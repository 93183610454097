import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';

import Layout from '../components/layout';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'argon',
  linkResolver: ({ node, key, link }) => doc => {
    if (doc.type === 'homepage') return `/`;
    if (doc.type === 'company') return `/companies/${doc.uid}`;

    return `/${doc.uid}`;
  },
});
